import * as React from 'react';
import styled from 'styled-components/native';
import { Platform, Pressable, View, } from 'react-native';
import { StackNavigationProp } from '@react-navigation/stack';

import { TextBold, TextSemiBold, ProgressBar, Card } from '../../Components';
import { CARD_HEIGHT } from '../../Components/Card';
import { Icon, Button } from 'native-base';
import { theme } from '../../styles';
import { SwipeRow } from 'react-native-swipe-list-view';
import * as firebase from 'firebase';
import { GoalType } from '../../types'
import type { Goal, RootStackParamList } from '../../types';

const BACK_BUTTON_WIDTH = 100 as const;

const CardHeaderWrapper = styled(View)`
  flex-direction: row;
  align-items: flex-start;
`

const IconWrapper = styled(View)`
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background: #efefef;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`

const HeaderDescriptionWrapper = styled(View)`
  flex: 1;
`

const RowButton = styled(Button) <{ isFirst?: boolean }>`
  width: ${BACK_BUTTON_WIDTH}px;
  position: absolute;
  height: ${CARD_HEIGHT}px;
  top: 0;
  height: ${CARD_HEIGHT}px;
  right: ${({ isFirst }) => isFirst ? BACK_BUTTON_WIDTH : 0}px;
  background: ${theme.colors.mainColor};
  text-align: center;
  justify-content: center;
  align-items: center;
`

const ButtonsWrapper = styled(Pressable)`
  border-radius: 10px;
  width: 99%;
  height: ${CARD_HEIGHT}px;
  overflow: hidden;
  position: absolute;
  right: 1px;
  background: #d32f2f;
`

const ProgressDescription = styled(View)`
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  margin: 5px 0%;
`
type Props = Readonly<{
  data: Goal;
  navigation: StackNavigationProp<RootStackParamList, 'Dashboard'>;
  showSlidePreview?: boolean;
}>

export const RowCard = ({ data, navigation, showSlidePreview }: Props) => {
  const date = new Date(data.last_edit?.toDate());
  const dateFormated = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
  const deadline = new Date(data.deadline);
  const deadlineFormated = `${deadline.toLocaleDateString()}`
  const rowRef = React.useRef<SwipeRow<{}> | null>(null);
  const visibleRowRef = React.useRef()

  const showGoal = ({ type }: { type: GoalType }) => {
    switch (type) {
      case GoalType.DateTarget:
      case GoalType.OpenEnded:
        navigation.navigate('OpenEndedGoal', { goalId: data.id, goal: data })
        break;
      case GoalType.Recurring:
        navigation.navigate('RecurringGoal', { goalId: data.id, goal: data })
        break;
      default:
        break;
    }

    rowRef.current?.closeRow();
  }

  return (
    <SwipeRow
      ref={rowRef}
      rightOpenValue={-200}
      swipeToOpenPercent={25}
      disableRightSwipe
      preview={showSlidePreview}
      recalculateHiddenLayout
      useNativeDriver
      // Hack because otherwise it will fallback to closing the row
      onRowPress={Platform.OS === 'web' ? () => null : () => showGoal({ type: data.type })}
    >
      <ButtonsWrapper>
        <RowButton isFirst style={{
          backgroundColor: theme.colors.errorRed
        }}
          onPress={() => firebase.firestore().collection('goals').doc(data.id).delete()}
        >
          <TextBold style={{ color: 'white' }}>Delete</TextBold>
        </RowButton>
        <RowButton
          onPress={() => showGoal({ type: data.type })}
        >
          <TextBold style={{ color: 'white' }}>View</TextBold>
        </RowButton>
      </ButtonsWrapper>
      {/* the onPress is a hack because it needs it to be present in order to fire onRowPress */}
      <Card onPress={() => null} ref={visibleRowRef}>
        <CardHeaderWrapper>
          <IconWrapper>
            <Icon
              type="MaterialCommunityIcons"
              name={
                data.type === GoalType.OpenEnded
                  ? 'target'
                  : data.type === GoalType.DateTarget
                    ? 'calendar'
                    : 'calendar-repeat'
              }
              style={{ color: theme.colors.mainColor }}
            />
          </IconWrapper>
          <HeaderDescriptionWrapper>
            <TextSemiBold style={{color: theme.colors.darkGray }}>{data.title}</TextSemiBold>
            <TextBold style={{color: 'gray', fontSize: 12}}>
              {date ? dateFormated : 'No updates yet'}
            </TextBold>
          </HeaderDescriptionWrapper>
          {
            (
              data.type === GoalType.DateTarget
              || data.type === GoalType.Recurring
            ) && (
              <View style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}>
                <Icon
                  type="MaterialCommunityIcons"
                  name={data.type === GoalType.DateTarget ? 'calendar' : 'calendar-repeat'}
                  style={{
                    fontSize: 14,
                    color: theme.colors.lightGray,
                    marginRight: 5
                  }}
                />
                <TextSemiBold style={{
                  fontSize: 13,
                  color: theme.colors.lightGray
                }}>
                  {
                    data.type === GoalType.DateTarget
                    ? deadlineFormated
                    : `Every ${data.frequency.toLowerCase()}`
                  }
                </TextSemiBold>
              </View>
            )
          }
        </CardHeaderWrapper>
        {
          data.type !== GoalType.Recurring && (
            <View style={{marginTop: 25}}>
              <ProgressBar
                progress={(100 / data.target) * data.progress}
              />
              <ProgressDescription>
                <TextSemiBold style={{fontSize: 14, color: theme.colors.lightGray }}>
                  {data.progress}
                </TextSemiBold>
                <TextSemiBold style={{fontSize: 14, color: theme.colors.lightGray }}>
                  {data.target}
                </TextSemiBold>
              </ProgressDescription>
            </View>
          )
        }
      </Card>
    </SwipeRow>
  )
}
