import * as React from "react";
import { Animated, Dimensions, StyleSheet, View, Platform, Pressable } from "react-native";
import { TextBold, TextRegular, TextSemiBold, Button } from "../../Components";
import styled from 'styled-components/native';
import { GoalEntry } from "../../types";
import { theme } from "../../styles";
import { Icon, Row, Col } from 'native-base';

const { width } = Dimensions.get("window");
const ratio = 228 / 362;
const DEFAULT_CARD_WIDTH = width * 0.75 > 400 ? 400 : width * 0.75;
const DEFAULT_CARD_HEIGHT = DEFAULT_CARD_WIDTH * ratio;
const MARGIN = 16;
const CARD_HEIGHT = DEFAULT_CARD_HEIGHT + MARGIN * 2;
const { height: wHeight } = Dimensions.get("window");
const height = wHeight - 64;
const styles = StyleSheet.create({
  card: {
    marginVertical: MARGIN,
    alignSelf: "center",
  },
});

interface WalletCardProps {
  y: Animated.Value;
  index: number;
  item: GoalEntry;
  navigateToEditNewEntry: () => void;
}

const WalletCard = ({ y, index, item, navigateToEditNewEntry }: WalletCardProps) => {
  const position = Animated.subtract(index * CARD_HEIGHT, y);
  const isDisappearing = -CARD_HEIGHT;
  const isTop = 0;
  const isBottom = height - CARD_HEIGHT;
  const isAppearing = height;

  const translateY = Animated.add(
    Animated.add(
      y,
      y.interpolate({
        inputRange: [0, 0.00001 + index * CARD_HEIGHT],
        outputRange: [0, -index * CARD_HEIGHT],
        extrapolateRight: "clamp",
      })
    ),
    position.interpolate({
      inputRange: [isBottom, isAppearing],
      outputRange: [0, -CARD_HEIGHT / 4],
      extrapolate: "clamp",
    })
  );
  const scale = position.interpolate({
    inputRange: [isDisappearing, isTop, isBottom, isAppearing],
    outputRange: [0.5, 1, 1, 0.5],
    extrapolate: "clamp",
  });
  const opacity = position.interpolate({
    inputRange: [isDisappearing, isTop, isBottom, isAppearing],
    outputRange: [0.5, 1, 1, 0.5],
  });
  return (
    <Animated.View
      style={[styles.card, { opacity, transform: [{ translateY }, { scale }] }]}
      key={index}
    >
      <Card item={item} navigateToEditNewEntry={navigateToEditNewEntry} />
    </Animated.View>
  );
};

export default WalletCard;

type NewEntryCard = Readonly<{
  id: -1;
  callback: () => void
}>

type Props = Readonly<{
  item: GoalEntry | NewEntryCard;
  navigateToEditNewEntry: () => void;
}>

const Card = ({ item, navigateToEditNewEntry }: Props) => {
  if (item.id === -1) {
    return (
      <CardButton
        width={DEFAULT_CARD_WIDTH}
        height={DEFAULT_CARD_HEIGHT}
        onPress={item.callback}
        style={{
          backgroundColor: theme.colors.mainColor
        }}
      >
        <View style={{
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1
        }}>
          <Icon
            type="MaterialCommunityIcons"
            name="plus"
            style={{
              margin: 0,
              color: 'white',
              fontSize: 50
            }}
          />
          <TextBold style={{
            marginLeft: -15,
            color: 'white',
            fontSize: 18
          }}>
            Add entry
            </TextBold>
        </View>
      </CardButton >
    )
  }

  if (!item.to) {
    return null;
  }

  const date = item.created_at?.toDate();

  if (!date) return null;

  return (
    <CardWrapper onPress={() => navigateToEditNewEntry(item.id)} width={DEFAULT_CARD_WIDTH} height={DEFAULT_CARD_HEIGHT}>
      <Row size={2}>
        <Col>
          <TextBold style={{ fontSize: 14, color: theme.colors.lightGray }}>
            {date.toLocaleDateString()} - {date.toLocaleTimeString()}
          </TextBold>
        </Col>
      </Row>
      <Row size={8}>
        <Col>
          <LabelText>
            Notes
          </LabelText>
          <EntryNotes>
            {item.notes}
          </EntryNotes>
        </Col>
        <Col>
        <LabelText style={{textAlign: 'center'}}>
          Progress made
        </LabelText>
          <TextBold style={{ fontSize: 50, color: theme.colors.mediumGray, textAlign: 'center' }}>
            {item.to - item.from}
          </TextBold>
        </Col>
      </Row>
    </CardWrapper>
  )
}

const EntryNotes = styled(TextRegular)`
    border-style: solid;
    border-left-width: 3px;
    border-left-color: #808080;
    background: #fafafa;
    margin-right: 20px;
    padding: 12px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    font-size: 15px;
    color: ${theme.colors.darkGray};
`

const LabelText = styled(TextSemiBold)`
  color: ${theme.colors.lightGray};
  font-size: 14px;
  margin-bottom: 15px;
`

const CardWrapper = styled(Pressable)`
  height: ${DEFAULT_CARD_HEIGHT}px;
  width: ${DEFAULT_CARD_WIDTH}px;
  padding: ${DEFAULT_CARD_HEIGHT / 8}px;
  background: white;
  border-radius: 25px;
  elevation: 2;
  ${() => (
    Platform.OS === 'web' && (
      'box-shadow: 0px 4px 4px rgba(0,0,0,.15);'
    )
  )}
`;

const CardButton = styled(Button)`
  height: ${DEFAULT_CARD_HEIGHT}px;
  width: ${DEFAULT_CARD_WIDTH}px;
  padding: ${DEFAULT_CARD_HEIGHT / 8}px;
  border-radius: 25px;
  elevation: 2;
  ${() => (
    Platform.OS === 'web' && (
      'box-shadow: 0px 4px 4px rgba(0,0,0,.15);'
    )
  )}
`
