export const theme = {
  colors: {
    mainColor: '#1E88E5',
    lightGray: '#868686',
    mediumGray: '#757575',
    darkGray: '#262626',
    successGreen: '#43A047',
    errorRed: '#d32f2f',
  }
};
