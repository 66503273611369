import * as React from 'react';
import styled from 'styled-components/native';
import {
  Text as NativeBaseText,
} from 'native-base';

export const TextLight = styled(NativeBaseText)`
  font-family: 'Quicksand_300Light';
`

export const TextRegular = styled(NativeBaseText)`
  font-family: 'Quicksand_400Regular';
`

export const TextMedium = styled(NativeBaseText)`
  font-family: 'Quicksand_500Medium';
`

export const TextSemiBold = styled(NativeBaseText)`
  font-family: 'Quicksand_600SemiBold';
`
export const TextBold = styled(NativeBaseText)`
  font-family: 'Quicksand_700Bold';
`
