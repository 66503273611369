export type InitialState = {
  user: UserInfo;
}

export type UserInfo = AnonymousUserInfo | AuthUserInfo

export type AnonymousUserInfo = {
  provider: Providers.Anonymous,
  name: 'User',
  picture: '',
  id: string;
}

export type AuthUserInfo = {
  provider: Providers[];
  name: string;
  picture: string;
  id: string;
  email: string;
}

export enum Providers {
  Fb = 'facebook.com',
  Google = 'google.com',
  Anonymous = 'anonymous'
}

export type RootStackParamList = {
  Login: undefined,
  Dashboard: undefined,
  AddNewGoal: undefined,
  OpenEndedGoal: { goalId: string; goal: Goal},
  OpenEndedGoalAddEntry: {
      goalId: string;
      target: number;
      progress: number;
  } | { edit: boolean; entryId: string };
  RecurringGoal: {
    goalId: string;
    goal: Goal
  };
  RecurringGoalAddEntry: {
    goalId: string;
    target: number;
  } | { edit: boolean; entryId: string };
  Terms: undefined;
}

export enum GoalType {
  OpenEnded = 'OpenEnded',
  DateTarget = 'DateTarget',
  Recurring = 'Recurring',
}

export enum RecurringFrequency {
  Day = 'Day',
  Week = 'Week',
  Month = 'Month'
}

export type Goal = {
  deadline?: string;
  frequency: RecurringFrequency;
  last_edit: firebase.firestore.Timestamp;
  progress: number;
  target: number;
  title: string;
  type: GoalType
  user_id: string;
  id: string;
  created_at: firebase.firestore.Timestamp
}

export type GoalEntryDate = {
  created_at: firebase.firestore.Timestamp
  id: string;
  notes: string;
  from: number;
  to: number;
}

export type GoalEntryRecurring = {
  created_at: firebase.firestore.Timestamp
  id: string;
  notes: string;
  progress: number;
}

export type GoalEntry = GoalEntryDate | GoalEntryRecurring
