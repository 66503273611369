import * as React from 'react';
import type { InitialState, UserInfo, } from './types';

export enum Providers {
  Fb = 'facebook.com',
  Google = 'google.com',
  Anonymous = 'anonymous'
}

const initialState: InitialState = {
  user: {
    provider: [],
    name: '',
    picture: '',
    id: '',
    email: '',
  }
};

type SettingsType = [InitialState, React.Dispatch<SettingAction>];

const SettingsContext = React.createContext<SettingsType | undefined>(undefined);

type SettingAction = 
  | { type: 'set_user', payload: UserInfo}
  | { type: 'log_out' }

const reducer = (state: InitialState, action: Action) => {
  switch (action.type) {
    case 'set_user':
      return {
        ...state,
        user: {
          ...action.payload
        }
      };
    case 'log_out':
      return {
        ...state,
        user: initialState.user
      }
    default:
      return state;
  }
};

type Props = Readonly<{ children: React.ReactNode }>

const SettingsContextWrapper = ({ children }: Props) => {
  const store = React.useReducer(reducer, initialState);

  return (
    <SettingsContext.Provider value={store}>
      {children}
    </SettingsContext.Provider>
  );
};

export {
  SettingsContextWrapper as InitSettings,
  SettingsContext
};

export const useSettingsActions = () => {
  const context = React.useContext(SettingsContext);

  if (!context) {
    throw new Error('Context used without Provider')
  }

  const dispatch = context[1];

  const setUser = (payload: UserInfo) => {
      dispatch({
      type: 'set_user',
      payload,
    });
  }

  const logOut = () => {
    dispatch({
      type: 'log_out'
    })
  }

  return {
    setUser,
    logOut
  }
}

export const useSettings = () => {
  const context = React.useContext(SettingsContext);

  if (!context) {
    throw new Error('Conext used without Provider')
  }

  return context[0];
}

export default SettingsContext.Consumer;
