import 'react-native-gesture-handler';
import * as React from 'react';
import { View } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { TransitionSpecs } from '@react-navigation/stack';
import styled from 'styled-components/native';

import * as Font from "expo-font";

import { initFirebase } from './firebase';
import { InitSettings, Providers, useSettings } from './Settings';
import {
  useFonts,
  Quicksand_300Light,
  Quicksand_400Regular,
  Quicksand_500Medium,
  Quicksand_600SemiBold,
  Quicksand_700Bold,
} from '@expo-google-fonts/quicksand';
import { AppLoading } from 'expo';
import type { Goal, RootStackParamList } from './types';

// Screens
import { Terms } from './Screens/Terms';
import { Dashboard } from './Screens/Dashboard';
import { Login } from './Screens/Login';
import { AddNewGoal } from './Screens/AddNewGoal';
import { OpenEndedGoal } from './Screens/OpenEndedGoal';
import { OpenEndedGoalAddEntry } from './Screens/OpenEndedGoal/OpenEndedGoalAddEntry'
import { RecurringGoal } from './Screens/RecurringGoal';
import { RecurringGoalAddEntry } from './Screens/RecurringGoal/RecurringGoalAddEntry';

const Stack = createStackNavigator<RootStackParamList>();
initFirebase();

export default function App() {
  const [isRobotoLoaded, setIsRobotoLoaded] = React.useState(false);

  const [fontsLoaded] = useFonts({
    Quicksand_300Light,
    Quicksand_400Regular,
    Quicksand_500Medium,
    Quicksand_600SemiBold,
    Quicksand_700Bold,
  });

  React.useEffect(() => {
    const loadFonts = async () => {
      await Font.loadAsync({
        Roboto: require("native-base/Fonts/Roboto.ttf"),
        Roboto_medium: require("native-base/Fonts/Roboto_medium.ttf"),
        Ionicons: require("@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/Ionicons.ttf"),
      })
      setIsRobotoLoaded(true);
    }
    loadFonts();
  }, [])

  const areFontsLoaded = fontsLoaded && isRobotoLoaded
  return (
    <InitSettings>
      {
        areFontsLoaded
          ? <AppWithProviders />
          : <AppLoading />
      }
    </InitSettings>
  );
}

const Body = styled(View)`
  background: #f2f2f2;
  flex: 1;
`

const slideInTransitionSpec = {
  open: TransitionSpecs.RevealFromBottomAndroidSpec,
  close: TransitionSpecs.FadeOutToBottomAndroidSpec
}

const AppWithProviders = () => {
  const { user } = useSettings();

  return (
    <Body>
      <NavigationContainer linking={{
        enabled: true,
        config: {
          screens: {
            Dashboard: '',
            Login: '/login',
            Terms: '/terms',
            OpenEndedGoal: {
              path: 'goal/:goalId',
              parse: {
                goalId: (goalId: string) => goalId,
                goal: () => null
              },
              stringify: {
                goal: () => 'true'
              },
            },
            OpenEndedGoalAddEntry: 'goal/:goalId/new_entry',
          }
        },
        prefixes: [
          'http://localhost:19006'
        ]
      }}>
        <Stack.Navigator>
          {
            !user.id
              ? (
                <Stack.Screen name="Login" component={Login} options={{
                  headerShown: false
                }} />
              )
              : (
                <>
                  <Stack.Screen name="Dashboard" component={Dashboard} options={{
                    headerShown: false,
                    animationEnabled: true,
                  }} />
                  <Stack.Screen name="AddNewGoal" component={AddNewGoal} options={{
                    animationEnabled: true,
                    transitionSpec: slideInTransitionSpec,
                    title: "Add new goal"
                  }} />
                  <Stack.Screen name="OpenEndedGoal" component={OpenEndedGoal} options={{
                    animationEnabled: true,
                    title: "Goal overview"
                  }} />
                  <Stack.Screen name="OpenEndedGoalAddEntry" component={OpenEndedGoalAddEntry} options={{
                    animationEnabled: true,
                    transitionSpec: slideInTransitionSpec,
                    title: "Add new entry"
                  }} />
                  <Stack.Screen name="RecurringGoal" component={RecurringGoal} options={{
                    animationEnabled: true,
                    title: "Goal overview"
                  }} />
                  <Stack.Screen name="RecurringGoalAddEntry" component={RecurringGoalAddEntry} options={{
                    animationEnabled: true,
                    transitionSpec: slideInTransitionSpec,
                    title: "Add new entry"
                  }} />
                </>
              )
          }
          <Stack.Screen name="Terms" component={Terms} options={{
            headerShown: false
          }} />
        </Stack.Navigator>

      </NavigationContainer>
    </Body>
  )
}
