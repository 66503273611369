import React from 'react';
import styled from 'styled-components/native';
import * as WebBrowser from 'expo-web-browser';
import * as Facebook from 'expo-auth-session/providers/facebook';
import { ResponseType, makeRedirectUri } from 'expo-auth-session';
import Constants from 'expo-constants'

import { Providers, useSettingsActions } from '../../Settings';
import * as firebase from 'firebase';
import { Icon, View } from 'native-base'
import { Button, TextSemiBold, TextBold, TextRegular } from '../../Components';

WebBrowser.maybeCompleteAuthSession();
const useProxy = Constants.appOwnership === 'expo'

const LoginButton = styled(Button)`
  width: 200px;
  align-items: center;
  justify-content: flex-start;
  padding: 0 10px;
  margin: 5px auto;
`

const LoginIcon = styled(Icon)`
  width: 30px;
  margin-right: 0px;
`

const Wrapper = styled(View)`
  align-items: center;
  justify-content: center;
  flex: 1;
`

const AppTitle = styled(TextBold)`
  font-size: 35px;
  text-align: center;
`

const AppDescription = styled(View)`
  margin: 50px 0;
`

export const Login = () => {
  console.log('redirect', makeRedirectUri({
    native: 'fb473867156653395://authorize',
    useProxy
  }))
  const { setUser } = useSettingsActions();
  const [request, response, promptAsync] = Facebook.useAuthRequest({
    clientId: '473867156653395',
    responseType: ResponseType.Token,
    redirectUri: makeRedirectUri({
      native: 'fb473867156653395://authorize',
      useProxy
    }),
  });

  React.useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (!user) return;
      console.log({user})

      if (user.isAnonymous) {
        const userObject = {
          provider: Providers.Anonymous,
          name: 'User',
          picture: '',
          id: user.uid
        }
        setUser(userObject)
        return;
      }

      // User is signed in.
      firebase.firestore()
        .collection('users')
        .doc(user.uid).get().then(doc => {
          if (!doc.exists) return

          setUser(doc.data());
        })
    })
  }, [])

  React.useEffect(() => {
    if (response?.type === 'success') {
      const { access_token } = response.params;


      const credential = firebase.auth.FacebookAuthProvider.credential(access_token);
      // Sign in with the credential from the Facebook user.
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        .then(() => firebase.auth().signInWithCredential(credential).then(response => {
          console.log({response})
          const userObject = {
            id: response.user?.uid,
            email: response.user?.email,
            provider: response.credential?.providerId === 'facebook.com'
              ? Providers.Fb
              : Providers.Google,
            name: `${response.additionalUserInfo?.profile?.name ?? 'User'}`,
            picture: response.additionalUserInfo?.profile?.picture?.data?.url ?? ''
          }
          setUser(userObject);
          firebase.firestore().collection('users').doc(response.user?.uid).set(userObject)
        }))
    }
  }, [response]);

  return (
    <Wrapper>
      <AppDescription>
        <AppTitle>
          GOALIT
        </AppTitle>
        <TextRegular>
          Login and start tracking your goals
        </TextRegular>
      </AppDescription>
      <LoginButton
        rounded
        disabled={!request}
        onPress={() => {
          promptAsync({ useProxy });
        }}
      >
        <LoginIcon
          type="FontAwesome5"
          name='facebook'
          style={{color: 'white', marginRight: 0}}
        />
        <TextSemiBold>Facebook</TextSemiBold>
      </LoginButton>
      <LoginButton
        rounded
        disabled={!request}
        onPress={() => {
          promptAsync({ useProxy });
        }}
      >
        <LoginIcon
          type="FontAwesome5"
          name='google'
          style={{color: 'white', marginRight: 0}}
        />
        <TextSemiBold>Google</TextSemiBold>
      </LoginButton>
      <LoginButton
        rounded
        onPress={() => {
          firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
            .then(() => firebase.auth().signInAnonymously().then(response => {
              const userObject = {
                provider: Providers.Anonymous,
                name: 'User',
                picture: '',
                id: response.user?.uid
              }
              setUser(userObject);
        }))}}
      >
        <LoginIcon
          type="FontAwesome5"
          name='mask'
          style={{color: 'white', marginRight: 0}}
        />
        <TextSemiBold>Anonymous</TextSemiBold>
      </LoginButton>
    </Wrapper>
  );
}
