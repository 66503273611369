import * as React from 'react';
import styled from 'styled-components/native';
import { StackScreenProps } from '@react-navigation/stack';

import { ProgressBar, TextBold, TextSemiBold, WideButton } from '../../Components';
import { View, TextInput } from 'react-native';
import { Row, Textarea } from 'native-base';
import { theme } from '../../styles';
import type { Goal, RootStackParamList } from '../../types';
import * as firebase from 'firebase';
import { debug } from 'react-native-reanimated';

const Wrapper = styled(View)`
  padding: 10px;
  align-items: flex-start;
  width: 100%;
  flex: 1;
`

const LabelText = styled(TextSemiBold)`
  color: ${theme.colors.lightGray};
  font-size: 14px;
  margin-bottom: 15px;
`

const InfoWrapper = styled(View)`
  margin-bottom: 50px;
  width: 100%;
`

const ProgressInputWrapper = styled(View)`
  flex-direction: row;
  align-items: center;
`

const ProgressTargetText = styled(TextBold)`
  font-size: 30px;
  color: ${theme.colors.lightGray};
  margin-right: 10px;
`

const StyledProgressBar = styled(ProgressBar)`
  width: 100%;
`

const ProgressBarNumbersWrapper = styled(View)`
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 10px;
  height: 30px;
`

const ProgressBarNumbers = styled(TextSemiBold)`
  font-size: 14px;
  color: ${theme.colors.lightGray};
`

const PushContent = styled(View)`
  width: 100%;
  flex: 1;
  padding: 40px;
`

const ProgressInput = styled(TextInput)`
  background: white;
  border-radius: 10px;
  width: 100px;
  min-width: 100px;
  height: 50px;
  font-size: 30px;
  padding: 0 15px;
  color: ${theme.colors.darkGray};
`

type Props = StackScreenProps<RootStackParamList, 'RecurringGoalAddEntry'>

export const RecurringGoalAddEntry = ({ route, navigation }: Props) => {
  const goalId = route.params?.goalId;
  const goalTarget = route.params?.target;
  const isEdit = route.params?.edit
  const entry = route.params?.entry;
  const goalProgress = isEdit ? entry?.progress : 0;
  const explicitDate = route.params?.explicitDate;

  const lastProgress = isEdit
    ? entry.progress
    : 0

  const lastNotes = isEdit
    ? entry.notes
    : ''


  const [newProgress, setNewProgress] = React.useState(() => lastProgress);
  const [notes, setNotes] = React.useState(lastNotes);


  const progressStep = 100 / goalTarget;
  const newProgressPercentage =
    goalProgress * progressStep + (newProgress - lastProgress) * progressStep

  const handleEntryAdd = () => {
    const goalRef = firebase.firestore()
      .collection('goals')
      .doc(goalId)

    const entryRef = goalRef.collection('entries').doc()

    goalRef.update({
      last_edit: firebase.firestore.FieldValue.serverTimestamp()
    })
    entryRef.set({
      id: entryRef.id,
      progress: newProgress,
      notes,
      created_at: explicitDate
        ? firebase.firestore.Timestamp.fromDate(explicitDate)
        : firebase.firestore.FieldValue.serverTimestamp()
    }).then(() => navigation.goBack())
  }

  const handleEntryDelete = () => {
    const goalRef = firebase.firestore()
      .collection('goals')
      .doc(goalId)

    const entryRef = goalRef.collection('entries').doc(entry.id)

    goalRef.update({
      progress: goalProgress - lastProgress,
      edit: firebase.firestore.FieldValue.serverTimestamp()
    })

    entryRef.delete().then(() => navigation.goBack())
  }

  const handleEntryUpdate = () => {
    const goalRef = firebase.firestore()
      .collection('goals')
      .doc(goalId)

    const entryRef = goalRef.collection('entries').doc(entry.id)

    goalRef.update({
      edit: firebase.firestore.FieldValue.serverTimestamp()
    })


    entryRef.set({
      id: entryRef.id,
      progress: newProgress,
      notes,
      created_at: explicitDate
        ? firebase.firestore.Timestamp.fromDate(explicitDate)
        : firebase.firestore.FieldValue.serverTimestamp()

    }).then(() => navigation.goBack())
  }

  console.log({newProgress})

  return (
    <Wrapper>
      <PushContent>

        <InfoWrapper>
          <StyledProgressBar
            progress={newProgressPercentage}
          />
          <ProgressBarNumbersWrapper>
            <ProgressBarNumbers>
              {goalProgress + (newProgress - lastProgress)}
            </ProgressBarNumbers>
            <ProgressBarNumbers>
              {goalTarget}
            </ProgressBarNumbers>
          </ProgressBarNumbersWrapper>

        </InfoWrapper>
        <InfoWrapper>
          <LabelText>
            Progress made
          </LabelText>
          <ProgressInputWrapper>
            <ProgressTargetText>
              Add
            </ProgressTargetText>
            <ProgressInput
              value={String(newProgress)}
              placeholder="5"
              keyboardType="number-pad"
              onChangeText={(text: string) => {
                const inputAsNumber = Number(text);
                if (Number.isNaN(inputAsNumber)) return;

                if (inputAsNumber > goalTarget) {
                  setNewProgress(goalTarget);
                  return;
                }
                setNewProgress(inputAsNumber)
              }}

            />
          </ProgressInputWrapper>
        </InfoWrapper>
        <InfoWrapper>
          <LabelText>
            Notes for this entry
          </LabelText>
          <Textarea
            style={{
              backgroundColor: "white",
              borderRadius: 10,
              width: '100%',
              paddingTop: 25,
              paddingBottom: 25,
              paddingLeft: 25,
              paddingRight: 25,
              fontSize: 20,
              minWidth: '100%',
              color: theme.colors.darkGray,
            }}
            rowSpan={8}
            placeholder="Easy peasy..."
            value={notes}
            onChangeText={setNotes}
          />
        </InfoWrapper>
      </PushContent>
      <View style={{
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        flex: 1,
        height: 50,
        maxHeight: 50,
      }}>
        {
          isEdit && (
            <>
              <WideButton
                onPress={handleEntryDelete}
                rounded
                style={{
                  backgroundColor: theme.colors.errorRed
                }}
              >
                <TextSemiBold style={{ fontSize: 16 }}>
                  Delete
            </TextSemiBold>
              </WideButton>
              <View style={{ width: 10 }} />
            </>
          )
        }
        <View style={{ flex: 1, maxHeight: 50, flexDirection: 'row', justifyContent: 'center' }}>
          <WideButton
            onPress={isEdit ? handleEntryUpdate : handleEntryAdd}
            rounded
            disabled={newProgress === 0}
            style={{
              backgroundColor: newProgress === 0 ? theme.colors.lightGray : theme.colors.mainColor
            }}
          >
            <TextSemiBold style={{ fontSize: 16, textAlign: 'center' }}>
              Update progress
            </TextSemiBold>
          </WideButton>
        </View>
      </View>
    </Wrapper>
  )
}
