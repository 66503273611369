import * as React from 'react';
import styled from 'styled-components/native';
import { Platform, Pressable, View } from 'react-native';
import { Container, Content, Form, Item, Label, Input, Radio, DatePicker, Col, Icon } from 'native-base';
import { TextBold, TextRegular, Button } from '../../Components';
import { theme } from '../../styles';
import { useSettings  } from '../../Settings';
import * as firebase from 'firebase';
import { StackNavigationProp } from '@react-navigation/stack';
import type { RootStackParamList } from '../../types';
import { GoalType, RecurringFrequency } from '../../types';
import type { Goal } from '../../types';

const GoalLabel = styled(TextBold)`
  font-size: 13px;
  color: ${theme.colors.lightGray};
`

const GoalTypeRow = styled(View)`
  margin: 15px 0 0 15px;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
`

type GoalTypeRadio = {
  type: GoalType;
  text: string;
}

export const getGoalTypes = (goalType: GoalType): GoalTypeRadio => {
  switch (goalType) {
    case GoalType.OpenEnded:
      return { type: GoalType.OpenEnded, text: 'Open ended' };
    case GoalType.DateTarget:
      return { type: GoalType.DateTarget, text: 'Target date' };
    case GoalType.Recurring:
      return { type: GoalType.Recurring, text: 'Recurring' };
    default:
      return { type: GoalType.OpenEnded, text: 'Open ended' };
  }
}

type RecurringFrequencyRadio = {
  type: RecurringFrequency;
  text: string;
}

const CustomContentItem = ({ children }: Readonly<{ children: React.ReactNode }>) => (
  <Item style={{ marginBottom: 25, width: '90%' }} >
    <View style={{ width: '90%', marginBottom: 15 }}>
      {children}
    </View>
  </Item>
)

type Props = Readonly<{
  navigation: StackNavigationProp<RootStackParamList, 'Dashboard'>
}>

export const AddNewGoal = ({ navigation }: Props) => {
  const [selectedGoalType, setSelectedGoalType] = React.useState<GoalType>(GoalType.OpenEnded)
  const [selectedFrequency, setSelectedFrequency] = React.useState<RecurringFrequency>(RecurringFrequency.Day)
  const [goalDate, setGoalDate] = React.useState('');
  const [title, setTitle] = React.useState('');
  const [goalTarget, setGoalTarget] = React.useState<number | null>(null);
  const { user } = useSettings();

  const handleSetNewGoal = () => {
    if (!title || !goalTarget) {
      return;
    }

    const newGoalRef = firebase.firestore().collection('goals').doc()
    const goal: Goal = {
      id: newGoalRef.id,
      user_id: user.id,
      title,
      target: goalTarget,
      type: selectedGoalType,
      frequency: selectedFrequency,
      deadline: goalDate,
      last_edit: firebase.firestore.FieldValue.serverTimestamp(),
      progress: 0,
      created_at: firebase.firestore.FieldValue.serverTimestamp()
    }
    newGoalRef.set(goal).then(() => {
      navigation.goBack()
    })
  }

  const canSubmit = 
    // Has title
    title.length > 0
    // and target
    && (goalTarget !== null && goalTarget > 0)
    // and if it has date target, it exists
    && (
      selectedGoalType !== GoalType.DateTarget
      || goalDate !== null
    )

  return (
    <View style={{ flex: 1 }}>
      <Container>
        <Content>
          <Form style={{ alignItems: 'center' }}>
            <Item style={{ marginBottom: 25, width: '90%' }} stackedLabel underline>
              <Label>
                <GoalLabel>
                  Title
                </GoalLabel>
              </Label>
              <Input
                value={title}
                onChangeText={setTitle}
                placeholder="Do 100 pushups"
              />
            </Item>
            <Item style={{ marginBottom: 25, width: '90%' }} stackedLabel underline>
              <Label>
                <GoalLabel>
                  Target
                </GoalLabel>
              </Label>
              <Input
                keyboardType="number-pad"
                placeholder="100"
                value={goalTarget ? `${goalTarget}` : undefined}
                onChange={(e) => {
                  const inputAsNumber = Number(e.nativeEvent.text);
                  if (Number.isNaN(inputAsNumber)) return;

                  setGoalTarget(inputAsNumber)
                }}
              />
            </Item>
            <CustomContentItem>
              <Label>
                <GoalLabel>
                  Goal type
                  </GoalLabel>
              </Label>
              {
                Object.values(GoalType)
                  .filter((type) => !Number.isFinite(type))
                  .map((enumValue) => {
                    // This is not safe but I don't know how else to do it
                    const goalType = enumValue as GoalType;
                    const { text, type } = getGoalTypes(goalType)
                    const handlePress = () => setSelectedGoalType(type);

                    return (
                      <Pressable onPress={handlePress} key={goalType}>
                        <GoalTypeRow>
                          <TextRegular>{text}</TextRegular>
                          <Radio
                            color={theme.colors.mainColor}
                            selectedColor={theme.colors.mainColor}
                            onPress={handlePress}
                            selected={selectedGoalType === type}
                          />
                        </GoalTypeRow>
                      </Pressable>
                    )
                  })
              }
            </CustomContentItem>
            {
              selectedGoalType === GoalType.DateTarget && (
                <CustomContentItem>
                  <Label>
                    <GoalLabel>
                      Goal end
                    </GoalLabel>
                  </Label>
                  <View>
                    {
                      // TS doesn't support yet RN/Expo module resolution
                      // https://github.com/microsoft/TypeScript/issues/21926
                      Platform.OS === 'web'
                        ? (
                          <input
                            type="date"
                            onChange={e => setGoalDate(new Date(e.target.value).toISOString())}
                          />
                        )
                        : (
                          <DatePicker
                            locale={"en"}
                            animationType={"fade"}
                            androidMode={"default"}
                            placeHolderText="Select date"
                            textStyle={{ color: theme.colors.darkGray }}
                            placeHolderTextStyle={{ color: theme.colors.lightGray }}
                            onDateChange={(date) => setGoalDate(new Date(date).toISOString())}
                          />
                        )
                    }
                  </View>
                </CustomContentItem>
              )
            }
            {
              selectedGoalType === GoalType.Recurring && (
                <CustomContentItem>
                  <Label>
                    <GoalLabel>
                      Repeat every
                    </GoalLabel>
                  </Label>
                  <View
                    style={{
                      flexDirection: 'row',
                      width: '100%',
                      marginLeft: 15,
                      marginTop: 15,
                      justifyContent: 'space-between'
                    }}
                  >
                    {
                      Object.values(RecurringFrequency)
                        .filter((type) => !Number.isFinite(type))
                        .map((enumValue) => {
                          // This is not safe but I don't know how else to do it
                          const frequencyType = enumValue as RecurringFrequency;
                          const handlePress = () => setSelectedFrequency(frequencyType);

                          return (
                            <Pressable onPress={handlePress} style={{ flex: 1 }}>
                              <Col style={{ flexDirection: 'row' }}>
                                <TextRegular style={{ marginRight: 10 }}>{frequencyType}</TextRegular>
                                <Radio
                                  color={theme.colors.mainColor}
                                  selectedColor={theme.colors.mainColor}
                                  selected={selectedFrequency === frequencyType}
                                  onPress={handlePress}
                                />
                              </Col>
                            </Pressable>
                          )
                        })

                    }
                  </View>
                </CustomContentItem>
              )
            }
            <View>
              <Button
                rounded
                style={{ justifyContent: 'center', paddingRight: 20, backgroundColor: !canSubmit ? theme.colors.lightGray : theme.colors.mainColor }}
                disabled={!canSubmit}
                onPress={handleSetNewGoal}
              >
                <Icon
                  type="Feather"
                  name="plus"
                  style={{ margin: 0, color: 'white' }}
                />
                <TextBold style={{ color: 'white', fontSize: 16, marginLeft: -20, padding: 0 }}>
                  Add
                </TextBold>
              </Button>
            </View>
          </Form>
        </Content>
      </Container>
    </View>
  )
}
