import * as React from 'react';
import styled from 'styled-components/native';
import { theme } from '../styles';
import type { Button as NativeBaseButtonType } from 'native-base';
import {
  Button as NativeBaseButton
} from 'native-base';

export const Button = styled(NativeBaseButton)`
  background-color: ${theme.colors.mainColor};
`

export const WideButtonWrapper = styled(Button)`
  flex: .9;
  justify-content: center;
`

type WideButtonProps = Readonly<{
  children: React.ReactNode;
  icon: React.ReactNode;
} & NativeBaseButtonType>

export const WideButton = ({ children, icon, ...buttonProps }: WideButtonProps) => (
  <WideButtonWrapper
    {...buttonProps}
  >
    {icon}
    {children}
  </WideButtonWrapper>
)
