import React from "react";
import { Animated, Dimensions, FlatList, View } from "react-native";

import { GoalEntry } from "../../types";
import EntryCard from './EntryCard';

const AnimatedFlatList = Animated.createAnimatedComponent(FlatList);

const EntriesList = ({ entries, navigateToAddNewEntry, navigateToEditNewEntry }: { entries: GoalEntry[]; navigateToAddNewEntry: () => void; }) => {
  const sortedEntriesByDate = entries.slice().sort((a, b) => (
    a.created_at < b.created_at ? 1 : -1
  ))
  const [y] = React.useState(() => new Animated.Value(0));

  return (
    <AnimatedFlatList
      showsVerticalScrollIndicator={false}
      scrollEventThrottle={16}
      bounces={false}
      style={{flex: 1}}
      onScroll={Animated.event(
        [
          {
            nativeEvent: {
              contentOffset: { y },
            },
          },
        ],
        { useNativeDriver: true }
      )}
      data={[{ id: -1, type: 'new', callback: navigateToAddNewEntry }, ...sortedEntriesByDate]}
      renderItem={({ index, item }) => (
        <EntryCard {...{ index, y, item, navigateToEditNewEntry }} />
      )}
      keyExtractor={(item) => `${item.id}`}
    />
  );
};

export default EntriesList;
