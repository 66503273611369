import * as React from 'react';
import styled from 'styled-components/native';
import { StackScreenProps } from '@react-navigation/stack';
import { SafeAreaView } from 'react-native-safe-area-context';

import { theme } from '../../styles';
import * as firebase from 'firebase';
import { View } from 'react-native';
import { Goal, GoalEntry, RootStackParamList, RecurringFrequency } from '../../types';
import { ProgressBar, TextRegular, TextSemiBold } from '../../Components';
import { Icon, Row, Col, Grid } from 'native-base';
import { addDays, addWeeks, addMonths, isSameDay, isSameWeek, isSameMonth, format } from "date-fns";

import { EntriesList } from './EntriesList';

const isEntryWithinAvailableDate = (entry: Date, availableDate: Date, repeat: RecurringFrequency) => {
  switch (repeat) {
    case RecurringFrequency.Day:
      return isSameDay(entry, availableDate)
    case RecurringFrequency.Week:
      return isSameWeek(entry, availableDate)
    case RecurringFrequency.Month:
      return isSameMonth(entry, availableDate);
    default:
      return false;
  }
}

const getEntriesSinceStart = (dateStart: Date, repeat: RecurringFrequency) => {
  const entries: Date[] = [];
  const now = new Date();
  let lastEntry = new Date(dateStart);
  let safe = 0;
  while (lastEntry < now) {
    safe++;
    if (safe > 1000) {
      break;
    }

    entries.push(lastEntry);
    switch (repeat) {
      case RecurringFrequency.Day:
        lastEntry = addDays(lastEntry, 1)
        break;
      case RecurringFrequency.Week:
        lastEntry = addWeeks(lastEntry, 1)
        break;
      case RecurringFrequency.Month:
        lastEntry = addMonths(lastEntry, 1)
        break;
      default:
        break;
    }
  }
  return entries;
}

const Wrapper = styled(SafeAreaView)`
  padding: 10px 50px 10px 50px;
  flex: 1;
`

const GoalDescription = styled(Col)`
  margin-bottom: 25px;
  margin-top: 0px;
`

const DescriptionItemWrapper = styled(View)`
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  margin-bottom: 8px;
  height: 25px;
`

const LastEditText = styled(TextSemiBold)`
  font-size: 14px;
  color: ${theme.colors.lightGray};
  align-items: center;
  justify-content: center;
`

type Props = StackScreenProps<RootStackParamList, 'RecurringGoal'>

export const RecurringGoal = ({ route, navigation }: Props) => {
  const goalId = route.params.goalId;
  const [goal, setGoal] = React.useState<Goal>()
  const [entries, setEntries] = React.useState<GoalEntry[]>([])

  React.useEffect(() => {
    if (!goalId) return;

    const goalRef = firebase.firestore()
      .collection('goals')
      .doc(goalId);


    goalRef.onSnapshot(snapshot => {
      const goal = snapshot.data() as Goal;
      console.log({goal})
      setGoal(goal)
    })

    goalRef.collection('entries').onSnapshot(snapshot => {
      const entries = snapshot.docs.map(doc => doc.data()) as GoalEntry[];
      setEntries(entries)
    })
  }, [route.params?.goalId])

  if (!goal) return (
    <TextRegular>
      Loading...
    </TextRegular>
  )

  if (!goal.last_edit || !goal.last_edit.seconds) return null;

  const lastEditDate = new Date(goal.last_edit?.toDate());
  const progressStep = 100 / goal.target;
  const progressAmount = progressStep * goal.progress;
  const createdAtDate = new Date(goal.created_at?.toDate());


  const availableEntries = getEntriesSinceStart(createdAtDate, goal.frequency)
    .map((date) => {
      const existingEntryForToday = entries.find(entry => {
        return isEntryWithinAvailableDate(entry.created_at?.toDate(), date, goal.frequency)
      });

      if (existingEntryForToday) {
        return existingEntryForToday;
      }

      return {
        date
      }
    });

    console.log({
      lastEditDate,
      createdAtDate,
      availableEntries,
      entries,
      goal
    })
  const completedEntries = entries.filter(entry => entry.progress === goal.target);

  return (
    <Wrapper>
      <Grid>
        <Row size={30} style={{
          maxWidth: 768,
          width: '90%',
          marginVertical: 0,
          marginHorizontal: 'auto'
        }}>
          <GoalDescription>
            <Row style={{margin: 0, padding: 0,}}>
              <Col size={45} style={{marginRight: '5%'}}>
                <TextSemiBold style={{ fontSize: 24, marginBottom: 10 }}>Goal</TextSemiBold>
                <TextRegular style={{ marginBottom: 15 }}>
                  {goal.title}
                </TextRegular>
              </Col>
              <Col size={45} style={{marginTop: 40, marginLeft: '5%'}}>
                <DescriptionItemWrapper>
                  <Icon
                    type="MaterialCommunityIcons"
                    name="target"
                    style={{ fontSize: 14, color: theme.colors.lightGray, marginRight: 8, width: 20,}}
                  />
                  <LastEditText>
                    Last update - {format(lastEditDate, 'dd/MM/yyyy')}
                  </LastEditText>
                </DescriptionItemWrapper>
                <DescriptionItemWrapper>
                  <Icon
                    type="Entypo"
                    name="time-slot"
                    style={{ fontSize: 14, color: theme.colors.lightGray, marginRight: 8, width: 20,}}
                  />
                  <LastEditText>
                    Repeat every - {goal.frequency}
                  </LastEditText>
                </DescriptionItemWrapper>
                <DescriptionItemWrapper>
                  <Icon
                    type="Entypo"
                    name="progress-two"
                    style={{ fontSize: 14, color: theme.colors.lightGray, marginRight: 8, width: 20, marginTop: 2 }}
                  />
                  <LastEditText>
                    Progress - {completedEntries.length}/{availableEntries.length}
                  </LastEditText>
                </DescriptionItemWrapper>
              </Col>
            </Row>
          </GoalDescription>
        </Row>
        <Row size={70}>
          <Col>
            {
              availableEntries.length > 0 && (
                <EntriesList
                  entries={availableEntries}
                  navigateToAddNewEntry={(date: Date) => {
                    navigation.navigate(
                      'RecurringGoalAddEntry',
                      {
                        goalId: goal.id,
                        target: goal.target,
                        explicitDate: date,
                      }
                    )
                  }}
                  navigateToEditNewEntry={(entryId: string, date: Date) => {
                    navigation.navigate(
                      'RecurringGoalAddEntry',
                      {
                        entryId,
                        goalId: goal.id,
                        edit: true,
                        target: goal.target,
                        entry: entries.find(entry => entry.id === entryId),
                        explicitDate: date
                      }
                    )
                  }}
                />
              )
            }
          </Col>
        </Row>
      </Grid>
    </Wrapper>
  )
};
