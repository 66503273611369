import * as React from 'react';
import styled from 'styled-components/native';
import { Pressable, Platform } from 'react-native';

export const CARD_HEIGHT = 150 as const;

export const Card = styled(Pressable)`
  height: ${CARD_HEIGHT}px;
  background: white;
  elevation: 2;
  ${() => (
    Platform.OS === 'web' && (
      'box-shadow: 0px 4px 4px rgba(0,0,0,.15);'
    )
  )}
  border-radius: 10px;
  padding: 10px;
`;
