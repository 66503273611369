import * as React from 'react';
import styled from 'styled-components/native';
import { theme } from '../../styles';

import { StatusBar, Animated, Platform, View, Image } from 'react-native';
import { Icon } from 'native-base';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Providers, useSettings, useSettingsActions } from '../../Settings';
import * as firebase from 'firebase';
import { useAssets } from 'expo-asset';

type Props = Readonly<{
  goalsCount: number;
  avatarSource: string;
  fontSizeValue: any;
  headerHeight: any;
  headerSubtitleSize: any;
  headerSubtitleOpacity: any;
  headerAvatarSize: any;
}>

export const Header = ({
  goalsCount,
  fontSizeValue,
  headerHeight,
  headerSubtitleSize,
  headerSubtitleOpacity,
  headerAvatarSize
}: Props) => {
  const { logOut } = useSettingsActions();
  React.useEffect(() => {
    StatusBar.setBackgroundColor('transparent')
    StatusBar.setTranslucent(true)
  }, [])
  const insets = useSafeAreaInsets();
  const { user } = useSettings();
  const source = Platform.OS === 'web'
    ? user.picture
    : { uri: user.picture }
  const [assets] = useAssets([require('../../assets/target_white.svg')])

  if (!assets) return null;

  return (
    <Animated.View style={{ height: headerHeight, overflow: 'hidden' }}>
      <Wrapper
        top={insets.top}
      >
        <AvatarWrapperView style={{ height: headerHeight, flexDirection: 'row' }}>
          {
            user.provider !== Providers.Anonymous && (
              <Animated.Image
                source={source}
                style={{
                  height: headerAvatarSize,
                  width: headerAvatarSize,
                  borderRadius: 44/2
                }}
              />
            )
          }
          <Animated.Text style={{
            fontSize: fontSizeValue,
            color: 'white',
            fontFamily: 'Quicksand_700Bold',
            marginLeft: 10,
          }}>
            {user.name || ''}
          </Animated.Text>
          <Icon
            type="AntDesign"
            name="logout"
            onPress={() => {
              logOut();
              firebase.auth().signOut()
            }}
            style={{ marginLeft: 16, fontSize: 20, color: 'white'}}
          />
        </AvatarWrapperView>
        <DescriptionWrapperView style={{ height: headerHeight }}>
          <Animated.Text style={{
            fontSize: headerSubtitleSize,
            opacity: headerSubtitleOpacity,
            color: 'white',
            fontFamily: 'Quicksand_600SemiBold',
            margin: 0
          }}>
            You have set up
          </Animated.Text>
          <Animated.Text style={{
            fontSize: fontSizeValue,
            color: 'white',
            fontFamily: 'Quicksand_700Bold',
            margin: 0,
          }}>
            {goalsCount} {goalsCount === 1 ? 'GOAL' : 'GOALS'}
          </Animated.Text>
        </DescriptionWrapperView>
      </Wrapper>
    </Animated.View>
  )
}

const Wrapper = styled(View)`
  padding: ${({ top }) => top + 20}px 20px 20px 20px;
  height: ${({ top }) => 100 + top}px;
  flex-direction: row;
  align-items: center;
  width: 100%;
  top: 0;
  justify-content: space-between;
  background: ${theme.colors.mainColor};
`

const DescriptionWrapperView = styled(Animated.View)`
  align-items: center;
  justify-content: center;
  top: 0px;
  position: absolute;
  right: 10px;
`

const AvatarWrapperView = styled(Animated.View)`
  align-items: center;
  justify-content: center;
  top: 0px;
  position: absolute;
  left: 10px;
`
