import * as React from 'react';
import styled from 'styled-components/native';
import { theme } from '../styles';
import { Animated, Platform, View } from 'react-native';

type Props = Readonly<{
  progress: number;
  color?: string;
  height?: number;
}>

const Rail = styled(View)`
  border-radius: 5px;
  background: #cfcfcf;
  position: relative;
  width: 100%;
`
const Track = styled(Animated.View)<{ color?: string; progress: number; }>`
  position: absolute;
  left: 0px;
  background: ${({ color }) => color || theme.colors.mainColor};
  border-radius: 50px;
`

const BAR_HEIGHT = 5;

export const ProgressBar = ({
  progress,
  color,
  height
}: Props) => {
  const roundedProgress = progress > 100
    ? 100
    : Math.ceil(progress);

  const trackColor = color || theme.colors.mainColor;

  const [trackWidth] = React.useState(new Animated.Value(0));
  const [trackColorFlag] = React.useState(new Animated.Value(0));

  const trackWidthPercentage = trackWidth.interpolate({
    inputRange: [roundedProgress, 100],
    outputRange: [`${roundedProgress}%`, '100%']
  })


  const trackColorInterpolation = trackColorFlag.interpolate({
    inputRange: [0, 1],
    outputRange: [trackColor, theme.colors.successGreen]
  })

  Animated.spring(trackWidth, {
    toValue: roundedProgress,
    useNativeDriver: Platform.OS === 'web'
  }).start()

  React.useEffect(() => {
    if (roundedProgress !== 100) {
      Animated.timing(trackColorFlag, {
        toValue: 0,
        useNativeDriver: Platform.OS === 'web'
      }).start()
      return;
    };

    Animated.timing(trackColorFlag, {
      toValue: 1,
      useNativeDriver: Platform.OS === 'web'
    }).start()
  }, [roundedProgress])
  
  return (
    <Rail style={{ height: height || BAR_HEIGHT }}>
      <Track style={{
        height: height || BAR_HEIGHT,
        width: trackWidthPercentage,
        backgroundColor: trackColorInterpolation
      }} />
    </Rail>
  )
}
