import * as React from 'react';
import styled from 'styled-components/native';
import { StackScreenProps } from '@react-navigation/stack';
import { SafeAreaView } from 'react-native-safe-area-context';

import { theme } from '../../styles';
import * as firebase from 'firebase';
import { View } from 'react-native';
import { Goal, GoalEntry, RootStackParamList } from '../../types';
import { ProgressBar, TextRegular, TextSemiBold } from '../../Components';
import { Icon, Row, Col, Grid } from 'native-base';
import EntriesList from './EntriesList';

const Wrapper = styled(SafeAreaView)`
  padding: 10px 50px 10px 50px;
  flex: 1;
`

const GoalDescription = styled(Col)`
  margin-bottom: 25px;
  margin-top: 0px;
`

const DescriptionItemWrapper = styled(View)`
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  margin-bottom: 8px;
  height: 25px;
`

const LastEditText = styled(TextSemiBold)`
  font-size: 14px;
  color: ${theme.colors.lightGray};
  align-items: center;
  justify-content: center;
`

type Props = StackScreenProps<RootStackParamList, 'OpenEndedGoal'>

export const OpenEndedGoal = ({ route, navigation }: Props) => {
  const goalId = route.params.goalId;
  const [goal, setGoal] = React.useState<Goal>()
  const [entries, setEntries] = React.useState<GoalEntry>()

  React.useEffect(() => {
    if (!goalId) return;

    const goalRef = firebase.firestore()
      .collection('goals')
      .doc(goalId);


    goalRef.onSnapshot(snapshot => {
      const goal = snapshot.data() as Goal;
      setGoal(goal)
    })

    goalRef.collection('entries').onSnapshot(snapshot => {
      const entries = snapshot.docs.map(doc => doc.data()) as GoalEntry[]; 
      setEntries(entries)
    })
  }, [route.params?.goalId])

  if (!goal) return (
    <TextRegular>
      Loading...
    </TextRegular>
  )

  const lastEditDate = new Date(goal.last_edit?.toDate()).toLocaleDateString();
  const progressStep = 100 / goal.target;
  const progressAmount = progressStep * goal.progress;

  console.log({ goal, entries })
  return (
    <Wrapper>
      <Grid>
        <Row size={30} style={{
          maxWidth: 768,
          width: '90%',
          marginVertical: 0,
          marginHorizontal: 'auto'
        }}>
          <GoalDescription>
            <Row style={{ margin: 0, padding: 0, height: 50, minHeight: 50 }}>
              <Col>
                <ProgressBar
                  progress={progressAmount}
                />
              </Col>
            </Row>
            <Row style={{margin: 0, padding: 0,}}>
              <Col size={45} style={{marginRight: '5%'}}>
                <TextSemiBold style={{ fontSize: 24, marginBottom: 10 }}>Goal</TextSemiBold>
                <TextRegular style={{ marginBottom: 15 }}>
                  {goal.title}
                </TextRegular>
              </Col>
              <Col size={45} style={{marginTop: 40, marginLeft: '5%'}}>
                <DescriptionItemWrapper>
                  <Icon
                    type="MaterialCommunityIcons"
                    name="target"
                    style={{ fontSize: 14, color: theme.colors.lightGray, marginRight: 8, width: 20,}}
                  />
                  <LastEditText>
                    Last update - {lastEditDate}
                  </LastEditText>
                </DescriptionItemWrapper>
                <DescriptionItemWrapper>
                  <Icon
                    type="Entypo"
                    name="time-slot"
                    style={{ fontSize: 14, color: theme.colors.lightGray, marginRight: 8, width: 20,}}
                  />
                  <LastEditText>
                    Deadline - None
                  </LastEditText>
                </DescriptionItemWrapper>
                <DescriptionItemWrapper>
                  <Icon
                    type="Entypo"
                    name="progress-two"
                    style={{ fontSize: 14, color: theme.colors.lightGray, marginRight: 8, width: 20, marginTop: 2 }}
                  />
                  <LastEditText>
                    Progress - {goal.progress}/{goal.target}
                  </LastEditText>
                </DescriptionItemWrapper>
              </Col>
            </Row>
          </GoalDescription>
        </Row>
        <Row size={70}>
          <Col>
            {
              entries && (
                <EntriesList
                  entries={entries}
                  navigateToAddNewEntry={() => {
                    navigation.navigate(
                      'OpenEndedGoalAddEntry',
                      {
                        goalId: goal.id,
                        target: goal.target,
                        progress: goal.progress
                      }
                    )
                  }}
                  navigateToEditNewEntry={(entryId: string) => {
                    navigation.navigate(
                      'OpenEndedGoalAddEntry',
                      {
                        entryId,
                        goalId: goal.id,
                        edit: true,
                        target: goal.target,
                        progress: goal.progress,
                        entry: entries.find(entry => entry.id === entryId),
                      }
                    )
                  }}
                />
              )
            }
          </Col>
        </Row>
      </Grid>
    </Wrapper>
  )
};
