import * as React from 'react';
import { View, Text, Platform, Animated, Pressable } from 'react-native';
import styled from 'styled-components/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { StackNavigationProp } from '@react-navigation/stack';
import * as firebase from 'firebase';
import { useSettingsActions, useSettings } from '../../Settings';
import { RowCard } from './RowCard';
import { Header } from './Header';
import { TextSemiBold, Button, TextRegular, WideButton, TextBold } from '../../Components';
import { Icon } from 'native-base';
import { theme } from '../../styles';
import { getGoalTypes } from '../AddNewGoal';
import type { Goal, RootStackParamList } from '../../types';
import { GoalType } from '../../types';

const FiltersWrapper = styled(View) <{ isActive?: boolean }>`
  width: 90%;
  margin: 20px auto;
  flex-direction: row;
  justify-content: center;
`;

const FilterText = styled(TextBold)`
  font-size: 12px;
  ${({ isActive }) => !isActive ? `
    color: ${theme.colors.lightGray};
  ` : `
  `};
  text-align: center;
  color: ${({ isActive }) => isActive ? '#fff' : theme.colors.mediumGray};
`

const FilterButton = styled(Button)`
  margin: 0 5px;
  font-size: 12px;
  ${({ isActive }) => !isActive ? `
    background-color: #f2f2f2;
    border: 1px solid ${theme.colors.mediumGray};
    border-color: ${theme.colors.mediumGray};
  ` : `
    
  `};
  min-width: 70px;
  padding: 0px;
  height: 30px;
  justify-content: center;
`

// magic number
const maxInterpolation = 90

type Props = Readonly<{
  navigation: StackNavigationProp<RootStackParamList, 'Dashboard'>
}>

export const Dashboard = ({ navigation }: Props) => {
  const { user } = useSettings();
  const [goals, setGoals] = React.useState<Goal[]>([]);
  const insets = useSafeAreaInsets();
  const [activeFilter, setActiveFilter] = React.useState<GoalType | 'All'>('All')
  const [showSlidePreview, setShowSlidePreview] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => (
      setShowSlidePreview(false)
    ), 1000)
  }, [])

  React.useEffect(function fetchData() {
    if (!user.id) return;

    firebase.firestore()
      .collection('goals')
      .where('user_id', '==', user.id)
      .orderBy('created_at', 'desc')
      .onSnapshot(snapshot => {
        const goalItems = snapshot.docs.map(doc => {
          // Casting data to type
          // There should be some validation logic here
          const goalData = doc.data() as Goal
          return goalData
        })
        setGoals(goalItems);
      })
  }, [user.id])

  const [fade] = React.useState(new Animated.Value(0));


  const value = fade.interpolate({
    inputRange: [0, maxInterpolation],
    outputRange: [24, 16],
    extrapolate: 'clamp'
  })

  const headerHeight = fade.interpolate({
    inputRange: [0, maxInterpolation],
    outputRange: [(100 + insets.top), 50 + insets.top],
    extrapolate: 'clamp'
  })

  const headerSubtitleSize = fade.interpolate({
    inputRange: [0, maxInterpolation],
    outputRange: [14, 0],
    extrapolate: 'clamp'
  })

  const headerSubtitleOpacity = fade.interpolate({
    inputRange: [0, maxInterpolation],
    outputRange: [.85, 0],
    extrapolate: 'clamp'
  })

  const headerAvatarSize = fade.interpolate({
    inputRange: [0, maxInterpolation],
    outputRange: [45, 25],
    extrapolate: 'clamp'
  })

  const filteredGoals = React.useMemo(() => {
    if (activeFilter === 'All') return goals;

    return goals.filter((goal) => goal.type === activeFilter)
  }, [goals, activeFilter])

  if (!user.id) {
    return (
      <Text>Please login first</Text>
    )
  }

  return (
    <View style={{ flex: 1, height: Platform.OS === 'web' ? '100vh' : '100%' }}>
      <Header
        avatarSource={user.picture}
        goalsCount={goals.length}
        fontSizeValue={value}
        headerHeight={headerHeight}
        headerSubtitleSize={headerSubtitleSize}
        headerSubtitleOpacity={headerSubtitleOpacity}
        headerAvatarSize={headerAvatarSize}
      />
      <FiltersWrapper>
        {
          ['All', GoalType.OpenEnded, GoalType.DateTarget, GoalType.Recurring].map((enumValue) => {
            const goalType = enumValue as GoalType;
            const isActive = enumValue === activeFilter;
            const { text, type } = getGoalTypes(goalType)

            const filterType = enumValue === 'All'
              ? enumValue
              : type;

            const filterText = enumValue === 'All'
              ? enumValue
              : text;

            return (
              <FilterButton onPress={() => setActiveFilter(filterType)} rounded isActive={isActive}>
                <FilterText isActive={isActive}>{filterText}</FilterText>
              </FilterButton>
            )
          })
        }
      </FiltersWrapper>
      <Animated.ScrollView
        style={{
          flex: 1,
        }}
        onScroll={Animated.event(
          [
            {
              nativeEvent: {
                contentOffset: { y: fade },
              },
            },
          ],
        )
        }
      >
        {
          filteredGoals.map((goal, idx) => {
            return (
              <Pressable style={{ width: '90%', marginLeft: '5%' }} key={goal.id} onPress={() => {
                console.log('clicked')
                navigation.navigate('OpenEndedGoal', { goalId: goal.id, goal })
              }}>
                <RowCard
                  data={goal}
                  navigation={navigation}
                  showSlidePreview={idx === 0 && showSlidePreview}
                />
                <View style={{ height: 25 }} />
              </Pressable>
            )
          })
        }
      </Animated.ScrollView>
      <View style={{ flex: 1, maxHeight: 50, flexDirection: 'row', justifyContent: 'center' }}>
        <WideButton
          onPress={() => navigation.navigate('AddNewGoal')}
          rounded
          icon={<Icon
            type="MaterialCommunityIcons"
            name="target"
            style={{ margin: 0 }}
          />}
        >
          <TextSemiBold style={{ color: 'white', marginLeft: -15 }}>
            Add new goal
          </TextSemiBold>
        </WideButton>
      </View>
    </View>
  )
}
