import * as firebase from 'firebase';
import 'firebase/firestore';

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyAj4me0q2AmPUpOt0gaZyjh2Y4y3jBZeFQ",
  authDomain: "goalit.firebaseapp.com",
  databaseURL: "https://goalit.firebaseio.com",
  projectId: "goalit",
  storageBucket: "goalit.appspot.com",
  messagingSenderId: "1040849696129",
  appId: "1:1040849696129:web:17a2ed881c6a9847036480"
};

const initFirebase = () => {
  if (firebase.apps.length) return;

  firebase.initializeApp(firebaseConfig)
}

const fbProvider = new firebase.auth.FacebookAuthProvider();
const googleProvider = new firebase.auth.GoogleAuthProvider();

export { initFirebase, fbProvider }
